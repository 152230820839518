var site = site || {};

(function ($) {
  Drupal.behaviors.menuItem = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      if (!this.attached && $('.js-perfume-filters').attr('data-filter-values')) {
        site.getFilters(false);
        $('.js-perfume-filters').parent().addClass('gnav-perfume-dropdown');
        $('.js-perfume-filters').attr(
          'data-filter-values',
          $('.js-perfume-filters')
            .attr('data-filter-values')
            .replace(/(^[,\s]+)|([\s]+)|([,\s]+$)/g, '')
            .toLowerCase()
        );
        if (!$('.filter-text').hasClass('giftfinder')) {
          $('.filter-text').html($('.js-perfume-filters').attr('data-filter-text'));
        }
        var fragranceTypes = $('.js-fragrance-type');

        $.each(fragranceTypes, function (index, value) {
          $(value).attr(
            'data-perfume-fragrance',
            $(value)
              .attr('data-perfume-fragrance')
              .replace(/(^[,\s]+)|([\s]+)|([,\s]+$)/g, '')
              .toLowerCase()
          );
        });
        this.attached = true;
      }
      $('.js-menu-filter-content input', context).change(function (e) {
        e.preventDefault();
        var activeMenus = [];
        var showAll = $('.js-perfume-filters')
          .data('show-all')
          .replace(/(^[,\s]+)|([\s]+)|([,\s]+$)/g, '')
          .toLowerCase();

        if ($('.js-menu-filter-content input').is(':checked') === false) {
          $('.perfume-menu-wrapper').css('display', 'none');
          $('.filter-seperator').css('border-bottom', 'none');
        } else {
          $('.perfume-menu-wrapper').css('display', 'block');
          $('.filter-seperator').removeClass('hidden');
          $('.filter-seperator').css('border-bottom', '1px solid #dcdcdc');
        }
        if ($(this).val() !== showAll) {
          $(this).toggleClass('active');
          $('.js-menu-filter-content input.active').each(function () {
            activeMenus.push($(this).val().toLowerCase());
          });
          if ($('.js-menu-filter-content input.active').length !== 0) {
            filterMenus(activeMenus);
          } else {
            menuToggle();
          }
        } else {
          $('.js-menu-filter-content input:checkbox').not(':first').removeClass('active').removeAttr('checked');
          menuToggle();
          setMenuHeight();
        }
      });

      function filterMenus(params) {
        $('.js-menu-filter-content input:checkbox:first').removeAttr('checked');
        var $fragrance = $('.js-fragrance-type');

        $('.perfume-menu-wrapper').css('display', 'block');
        $fragrance.addClass('hidden');
        var unusual_combination = [];

        $.each($fragrance, function (index, val) {
          var current = $(val).attr('data-perfume-fragrance').toLowerCase();
          var dataArr = current.split(',');
          var $fragranceType = $('.js-fragrance-type[data-perfume-fragrance="' + current + '"] ');
          var filterResult = params.every(function (val) {
            return dataArr.indexOf(val) !== -1;
          });

          if (filterResult && current) {
            $(this).removeClass('hidden');
            $(
              '.site-header__section--nav .gnav-dropdown[data-columns="5"] .perfume-menu-wrapper .menu__item:nth-child(5n+1)'
            ).css('clear', 'inherit');
            $('.filter-seperator').css('border-bottom', '1px solid #dcdcdc');
            unusual_combination.push($fragranceType.length);
          }
        });
        $('.perfume-menu  .no-results, .perfume-menu .category-text').addClass('hidden');
        setMenuHeight();
        if (unusual_combination.length === 0) {
          $('.perfume-menu .no-results, .perfume-menu .category-text').removeClass('hidden');
          $('.filter-seperator').addClass('hidden');
        }
      }

      function menuToggle() {
        $('.js-fragrance-type').removeClass('hidden');
        $('.perfume-menu .no-results, .perfume-menu .category-text').addClass('hidden');
        $(
          '.site-header__section--nav .gnav-dropdown[data-columns="5"] .perfume-menu-wrapper .menu__item:nth-child(5n+1)'
        ).css('clear', 'none');
      }

      function setMenuHeight() {
        var menuHeight = $('.site-header__main').height();
        var submenuHeight = $('.js-perfume-filters').height();
        var windowHeight = $(window).height();
        var optWidth = submenuHeight + menuHeight;
        var filterMenu = $('.perfume-filter-section').height();
        var setHeight = submenuHeight - (menuHeight + filterMenu + 40);

        if (windowHeight < optWidth) {
          $('.gnav-perfume-dropdown').css({
            height: setHeight,
            overflow: 'scroll'
          });
        } else {
          $('.gnav-perfume-dropdown').css('height', 'auto');
        }
      }
    },
    attached: false
  };
})(jQuery);
